import { PartnerLogo, Text, WalletInstrument } from "@tigris/mesokit";
import {
  TransferFragment,
  TransferKind,
  TransferStatus,
} from "../generated/sdk";
import { TransferDetail } from "./TransferDetail";
import { TransferKindBadge } from "./TransferKindBadge";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatRelative } from "date-fns";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAppContext } from "../hooks/useAppContext";
import { useMemo, useState } from "react";
import {
  cryptoAssetFromExchangeRate,
  truncateAmount,
  networkFromExchangeRate,
} from "@tigris/common";

const transferDetailDialogAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const TransferStatusBadge = ({ status }: { status: TransferStatus }) => {
  switch (status) {
    case TransferStatus.EXECUTING:
      return (
        <div className="ml-2 flex items-center gap-1">
          <FontAwesomeIcon
            icon={icon({ name: "loader" })}
            size="lg"
            className="fa-spin"
          />
          <span className="text-sm font-semibold">In Progress...</span>
        </div>
      );
    case TransferStatus.DECLINED:
      return (
        <div className="ml-2 flex items-center gap-1 text-[#EC4848]">
          <FontAwesomeIcon
            icon={icon({ name: "exclamation-circle" })}
            size="lg"
            className="fa-solid"
          />
          <span className="text-sm font-semibold">Transfer Failed</span>
        </div>
      );
    default:
      return;
  }
};

export const TransferItem = ({ transfer }: { transfer: TransferFragment }) => {
  const { session } = useAppContext();
  const [showDetail, setShowDetail] = useState(false);
  const asset = cryptoAssetFromExchangeRate(transfer.exchangeRate);
  const transferKind = transfer.kind;
  const isCashIn = transferKind === TransferKind.CASH_IN;

  const fiatInstrument = useMemo(
    () =>
      session?.user?.fiatInstruments?.__typename === "FiatInstruments"
        ? session.user.fiatInstruments.collection.find((fi) =>
            isCashIn
              ? fi.id === transfer.sourceInstrumentId
              : fi.id === transfer.destInstrumentId,
          )
        : undefined,
    [isCashIn, session?.user?.fiatInstruments, transfer],
  );
  const walletInstrument = useMemo(() => {
    if (session?.user?.walletInstruments?.__typename === "WalletInstruments") {
      return session.user.walletInstruments.collection.find((wi) =>
        isCashIn
          ? wi.id === transfer.destInstrumentId
          : wi.address === transfer.cashOutSenderAddress,
      );
    }
  }, [isCashIn, session?.user?.walletInstruments, transfer]);
  const depositAddressInstrument = useMemo(
    () =>
      !isCashIn &&
      session?.user?.depositAddressInstruments?.__typename ===
        "DepositAddressInstruments"
        ? session.user.depositAddressInstruments.collection.find(
            (dai) => dai.id === transfer.sourceInstrumentId,
          )
        : undefined,
    [
      isCashIn,
      session?.user?.depositAddressInstruments,
      transfer.sourceInstrumentId,
    ],
  );

  const network = networkFromExchangeRate(transfer.exchangeRate);

  return (
    <>
      <div
        key={transfer.id}
        className="-mx-4 flex cursor-pointer items-center justify-between rounded-2xl p-4 text-neutral-800 transition-colors hover:bg-neutral-100 dark:text-white hover:dark:bg-neutral-800"
        onClick={() => setShowDetail(!showDetail)}
      >
        <div className="flex items-center gap-2">
          <PartnerLogo
            size="md"
            displayName={transfer.partner.displayName}
            logoUri={transfer.partner.logoUri}
          />
          <div className="flex flex-col">
            <div className="flex items-center">
              <Text className="text-sm font-semibold">
                {transfer.partner.displayName}
              </Text>
              <TransferStatusBadge status={transfer.status} />
            </div>
            <div className="flex gap-2 text-xs font-semibold capitalize opacity-60">
              {formatRelative(new Date(transfer.createdAt), Date.now()).replace(
                / at/,
                "",
              )}
              <WalletInstrument
                network={network}
                walletAddress={walletInstrument?.address ?? ""}
              />
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <TransferKindBadge kind={transfer.kind} />
          {transfer.kind === TransferKind.CASH_IN && (
            <div className="flex w-min flex-col text-right">
              <Text className="font-semibold">
                +{truncateAmount(asset, transfer.destination.amount)}{" "}
                {transfer.destination.currency}
              </Text>
              <Text className="text-sm font-semibold opacity-60">
                ${transfer.sourceTotal.amount}
              </Text>
            </div>
          )}
          {transfer.kind === TransferKind.CASH_OUT && (
            <div className="flex w-min flex-col text-right">
              <span className="font-semibold">
                -{truncateAmount(asset, transfer.sourceTotal.amount)}{" "}
                {transfer.sourceTotal.currency}
              </span>
              <span className="text-sm font-semibold">
                ${transfer.destination.amount}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Dialog uses React.createPortal to inject into DOM */}

      <AnimatePresence>
        {showDetail && (
          <Dialog
            as={motion.div}
            className="relative z-30"
            open={showDetail}
            onClose={() => setShowDetail(false)}
            {...transferDetailDialogAnimation}
            static
          >
            {/* https://github.com/tailwindlabs/headlessui/issues/2535#issuecomment-1672667145 */}
            <Dialog.Title />
            <div
              className="fixed inset-0 z-40 bg-white/60 dark:bg-neutral-950/60"
              aria-hidden="true"
            />
            <div className="fixed inset-0 z-50 flex h-full w-screen justify-center overflow-auto sm:pt-24">
              <div className="fixed right-2 top-2 z-10 flex h-8 w-8 items-center justify-center rounded-full border border-neutral-100/60 bg-neutral-100/40 text-neutral-700 sm:hidden">
                <FontAwesomeIcon icon={icon({ name: "close" })} />
              </div>
              <Dialog.Panel data-testid="transfer-detail-dialog">
                <TransferDetail
                  transfer={transfer}
                  fiatInstrument={fiatInstrument}
                  walletInstrument={walletInstrument}
                  depositAddressInstrument={depositAddressInstrument}
                />
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </AnimatePresence>
    </>
  );
};
