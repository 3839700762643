import {
  Title,
  Text,
  ThemeName,
  themeCollection,
  CardHero,
} from "@tigris/mesokit";
import { Amplitude } from "../telemetry";
import { AnimatePresence, motion } from "framer-motion";
import { Asset, Network } from "../types";
import { Transition } from "@headlessui/react";
import { resolveSetTheme, resolveUser } from "../api";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { useAppContext } from "../hooks/useAppContext";
import { useCallback, useState, Fragment } from "react";
import { TransferKind } from "../generated/sdk";

const TOAST_ID = "ProfileSettingsTheme";

export const ProfileSettingsTheme = () => {
  const { session, updateUser } = useAppContext();

  const setTheme = useCallback(
    async (themeName: ThemeName) => {
      Amplitude.track("Theme Set");
      const setThemeResult = await resolveSetTheme({ input: themeName });

      if (setThemeResult.isErr()) {
        toast.error(setThemeResult.error, { id: TOAST_ID });
        return;
      }

      const userResult = await resolveUser();

      if (userResult.isErr()) {
        toast.error("Unable to set theme.");
        return;
      }

      updateUser(userResult.value);

      toast.success(
        <>
          Theme changed to{" "}
          {`${themeName
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ")}`}
        </>,
        { id: TOAST_ID },
      );
    },
    [updateUser],
  );

  if (!session?.user || !session.user.theme) {
    return <></>;
  }

  return (
    <section className="mb-8" data-testid="ProfileSettingsTheme">
      <Title.Medium className="font-bold">Themes</Title.Medium>
      <Text className="mb-4">
        Customize your UI so you can recognize your Meso account, across all
        apps.
      </Text>

      <div className="grid items-center gap-8 md:grid-cols-2">
        <div className="grid grid-cols-4 gap-6">
          {Object.entries(themeCollection)
            .filter(([_, { userConfigurable }]) => userConfigurable)
            .map(([themeName]) => {
              return (
                <ThemeIcon
                  key={themeName}
                  currentThemeName={session!.user!.theme as ThemeName}
                  themeName={themeName as ThemeName}
                  updateTheme={() => setTheme(themeName as ThemeName)}
                />
              );
            })}
        </div>

        <div className="flex flex-col items-center gap-2">
          <div
            className={twMerge(
              "w-ts-card rounded-ts-card h-[180px] overflow-hidden",
              `theme-${session.user.theme}`,
            )}
          >
            <CardHero
              theme={session.user.theme as ThemeName}
              asset={Asset.USDC}
              amount="95.50"
              transferKind={TransferKind.CASH_IN}
              network={Network.ETHEREUM_MAINNET}
              walletAddress={"0x0000...0000"}
              canShowPopover={false}
            />
          </div>
          <Text className="text-xs font-bold uppercase leading-none tracking-wide opacity-50">
            Preview
          </Text>
        </div>
      </div>
    </section>
  );
};

const ThemeIcon = ({
  currentThemeName,
  themeName,
  updateTheme,
}: {
  /** The user's currently selected theme. */
  currentThemeName: ThemeName;
  themeName: ThemeName;
  updateTheme: (theme: ThemeName) => void;
}) => {
  const [hover, setHover] = useState(false);

  const wrapper = twMerge(
    "h-16 w-16 rounded-full active:scale-95 ring-4 cursor-pointer ring-transparent relative flex items-center justify-center transition-all",
    `theme-${themeName}`,
    themeName === currentThemeName && "ring-blue-500 dark:ring-white",
  );
  const themeIconClasses = "theme-icon w-full h-full absolute rounded-full";

  return (
    <div
      className={wrapper}
      data-testid={themeName}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => {
        updateTheme(themeName);
      }}
    >
      <AnimatePresence>
        {currentThemeName === themeName && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className="absolute z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-6 w-6 fill-neutral-700"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
      <div key={themeName} className={themeIconClasses} />
      <Transition
        as={Fragment}
        show={hover}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute -top-5 z-20 rounded-lg bg-white p-2 text-xs capitalize text-black shadow-lg dark:bg-neutral-700 dark:text-white">
          {themeName}
        </div>
      </Transition>
    </div>
  );
};
