import measurePasswordStrength from "zxcvbn";
import { Amplitude } from "../telemetry";
import { AnimatePresence, motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { ErrorMessages } from "../utils/errorMessages";
import { resolveChangePassword } from "../api";
import { toast } from "sonner";
import { useThrottle } from "@uidotdev/usehooks";
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  LabelledInput,
  PasswordStrength,
  PasswordValidity,
  Text,
  Title,
} from "@tigris/mesokit";
import {
  MIN_PASSWORD_LENGTH,
  defaultFormField,
  passwordSchema,
} from "@tigris/common";
import { AppContext } from "../contexts/AppContext";
import { dialogAnimation } from "../utils/animation";
import { FormField } from "../types";

const newPasswordAnimation = {
  initial: { opacity: 0, y: -48 },
  exit: { opacity: 0, y: -48 },
  animate: { opacity: 1, y: 0 },
};

type FormState = {
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  fields: Record<"currentPassword" | "newPassword", FormField>;
};

const defaultFormState: FormState = {
  isValid: false,
  isDirty: false,
  isTouched: false,
  fields: {
    currentPassword: defaultFormField(""),
    newPassword: defaultFormField(""),
  },
};

const FORM_ID = "ChangePassword";
const TOAST_ID = FORM_ID;

export const ProfileSettingsChangePassword = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNewPasswordInput, setShowNewPasswordInput] = useState(false);
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { session } = useContext(AppContext);
  const emailAddress = session?.user?.email || "";
  const throttled = useThrottle(formState.fields.newPassword, 500);
  const currentPasswordInput = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setIsOpen(false);
    setShowNewPasswordInput(false);
    setPasswordStrength(0);
    setFormState(defaultFormState);
    setIsLoading(false);
  };

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      if (!showNewPasswordInput) {
        setShowNewPasswordInput(true);
        return;
      }

      setIsLoading(true);

      const changePasswordResult = await resolveChangePassword({
        input: {
          currentPassword: formState.fields.currentPassword.value,
          newPassword: formState.fields.newPassword.value,
        },
      });

      if (changePasswordResult.isErr()) {
        toast.error(changePasswordResult.error);
        setIsLoading(false);
        setTimeout(() => {
          currentPasswordInput.current?.focus();
        }, 10);
        return;
      }

      toast.success("Successfully changed your password!");
      handleClose();
    },
    [
      formState.fields.currentPassword.value,
      formState.fields.newPassword.value,
      showNewPasswordInput,
    ],
  );

  const handlePasswordChange = useCallback<
    (field: keyof FormState["fields"]) => ChangeEventHandler<HTMLInputElement>
  >(
    (field) => (event) => {
      const newValue = event.target.value;

      setFormState((previousState) => {
        const isDirty =
          previousState.fields[field].isTouched ||
          newValue !== previousState.fields[field].value;

        const result = passwordSchema.safeParse(newValue);
        const isValid = result.success;
        if (!result.success) {
          const errorCodes = result.error.issues.map((issue) => issue.code);
          if (errorCodes.includes("too_big")) {
            toast.error(ErrorMessages.changePassword.TOO_BIG, {
              id: TOAST_ID,
            });
          }
        } else {
          toast.dismiss(TOAST_ID);
        }
        if (!isValid) {
          Amplitude.track("Form Input Invalid", {
            formId: FORM_ID,
            inputId: field,
          });
        }

        return {
          ...previousState,
          isDirty: previousState.isDirty || isDirty,
          isValid:
            isValid &&
            Object.entries(previousState.fields)
              .filter(([key, _]) => key !== field)
              .every(([_, { isValid }]) => isValid),
          fields: {
            ...previousState.fields,
            [field]: {
              ...previousState.fields[field],
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    },
    [],
  );

  const handleBlur = useCallback(
    (field: keyof FormState["fields"]) => () => {
      setFormState((previousState) => ({
        ...previousState,
        isTouched: true,
        isValid: Object.values(previousState.fields).every(
          (field) => field.isValid,
        ),
        isDirty: Object.values(previousState.fields).every(
          (field) => field.isDirty,
        ),
        fields: {
          currentPassword: {
            ...previousState.fields.currentPassword,
            isTouched:
              field === "currentPassword" ||
              previousState.fields.currentPassword.isTouched,
          },
          newPassword: {
            ...previousState.fields.newPassword,
            isTouched:
              field === "newPassword" ||
              previousState.fields.newPassword.isTouched,
          },
        },
      }));
    },
    [],
  );

  useEffect(() => {
    const passwordStrengthResult = measurePasswordStrength(throttled.value, [
      formState.fields.currentPassword.value,
      emailAddress,
    ]);
    setPasswordStrength(passwordStrengthResult.score);
  }, [emailAddress, formState.fields.currentPassword.value, throttled.value]);

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  return (
    <>
      <section data-testid="ProfileSettingsChangePassword">
        <Title.Medium className="font-bold">Password</Title.Medium>
        <Text>Change your password</Text>
      </section>
      <Button
        className="mt-4 px-4 text-sm font-bold"
        containerClassName="block"
        primary={false}
        onClick={() => setIsOpen(!isOpen)}
      >
        Change Password
      </Button>
      {/* Dialog uses React.createPortal to inject into DOM */}
      <AnimatePresence>
        {isOpen && (
          <Dialog
            as={motion.div}
            className="relative z-10"
            open={isOpen}
            onClose={handleClose}
            {...dialogAnimation}
            static
          >
            <div
              className="fixed inset-0 bg-white/50 dark:bg-black/50"
              aria-hidden="true"
            />
            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
              <Dialog.Panel
                data-testid="change-password-dialog"
                className="flex w-96 flex-col gap-4 rounded-xl bg-white p-8 text-black shadow-2xl md:rounded-3xl dark:bg-neutral-900"
              >
                <Title.Small className="font-bold">Change Password</Title.Small>
                {/* For password managers */}
                <form
                  id={FORM_ID}
                  name={FORM_ID}
                  data-testid={FORM_ID}
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-4"
                >
                  <input
                    className="hidden"
                    name="username"
                    autoComplete="username"
                    id="username"
                    value={emailAddress}
                    readOnly={true}
                  />

                  <LabelledInput
                    labelProps={{ text: "Enter current password" }}
                    name="currentPassword"
                    id="currentPassword"
                    type="password"
                    value={formState.fields.currentPassword.value}
                    data-testid={`${FORM_ID}:currentPassword`}
                    isValid={renderFieldAsValid("currentPassword")}
                    onChange={handlePasswordChange("currentPassword")}
                    onBlur={handleBlur("currentPassword")}
                    placeholder="Current Password"
                    autoComplete="password"
                    ref={currentPasswordInput}
                    disabled={isLoading}
                    autoFocus
                  />

                  {showNewPasswordInput && (
                    <motion.div {...newPasswordAnimation}>
                      <LabelledInput
                        labelProps={{ text: "Enter new password" }}
                        name="newPassword"
                        id="newPassword"
                        type="password"
                        value={formState.fields.newPassword.value}
                        data-testid={`${FORM_ID}:newPassword`}
                        isValid={renderFieldAsValid("newPassword")}
                        onChange={handlePasswordChange("newPassword")}
                        onBlur={handleBlur("newPassword")}
                        placeholder="New Password"
                        autoComplete="new-password"
                        disabled={isLoading}
                        autoFocus
                      />
                      <div className="mt-2 flex min-h-8 gap-2">
                        <PasswordValidity
                          isValid={formState.fields.newPassword.isValid}
                          minPasswordLength={MIN_PASSWORD_LENGTH}
                        />
                        <PasswordStrength
                          strength={passwordStrength}
                          validPasswordLength={
                            formState.fields.newPassword.isValid
                          }
                        />
                      </div>
                    </motion.div>
                  )}
                  <div className="flex flex-row items-center justify-between gap-1 md:gap-8">
                    <Button
                      className="h-8 bg-transparent px-3 text-xs text-black dark:text-white"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <div className="flex-grow">
                      <Button
                        className="h-8 px-4 text-sm font-bold"
                        primary={false}
                        disabled={
                          showNewPasswordInput
                            ? !formState.isValid
                            : !formState.fields.currentPassword.isValid
                        }
                        type="submit"
                      >
                        {showNewPasswordInput
                          ? "Save New Password"
                          : "Continue"}
                      </Button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </div>
          </Dialog>
        )}
      </AnimatePresence>
    </>
  );
};
