import {
  useCallback,
  FormEventHandler,
  ChangeEventHandler,
  useState,
  useRef,
} from "react";
import {
  resolveRequestPasswordReset,
  resolveSession,
  setBearerToken,
} from "../api";
import { AccountAppRoutes, PARTNER_ID } from "../utils/constants";
import { Amplitude } from "../telemetry";
import { AnimatePresence, motion } from "framer-motion";
import { Button, Input, Logo, Text, Title } from "@tigris/mesokit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultFormField, emailSchema } from "@tigris/common";
import { FormField, Session } from "../types";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { loginAnimation } from "../utils/animation";
import { toast } from "sonner";
import { useAppContext } from "../hooks/useAppContext";
import { useNavigate } from "react-router-dom";

type FormState = {
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  fields: Record<"email", FormField>;
};

const defaultFormState: FormState = {
  isValid: false,
  isDirty: false,
  isTouched: false,
  fields: { email: defaultFormField("") },
};

const FORM_ID = "RequestPasswordReset";

export const RequestPasswordReset = () => {
  const navigate = useNavigate();
  const { setSession } = useAppContext();
  const [formState, setFormState] = useState<FormState>(defaultFormState);
  const [isLoading, setIsLoading] = useState(false);
  const emailInput = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const sessionResult = await resolveSession({
        input: { partnerId: PARTNER_ID },
      });

      if (sessionResult.isErr()) {
        toast.error(sessionResult.error);
        return;
      }

      const newSession: Session = {
        id: sessionResult.value.id,
        token: sessionResult.value.token,
        riskSessionKey: sessionResult.value.riskSession.sessionKey,
      };

      setBearerToken(sessionResult.value.token, navigate);
      setSession(newSession);

      const requestPasswordResetResult = await resolveRequestPasswordReset({
        input: { email: formState.fields.email.value },
      });

      setIsLoading(false);

      if (requestPasswordResetResult.isErr()) {
        setTimeout(() => {
          emailInput.current?.focus();
        }, 10);
        toast.error(requestPasswordResetResult.error);
        return;
      }

      setFormState(defaultFormState);
      toast(
        <div className="flex items-center justify-center gap-2">
          <FontAwesomeIcon
            icon={icon({ name: "check-circle", style: "solid" })}
            className="text-[#09D389]"
          />
          <span>
            We sent a reset password link to{" "}
            <span className="font-semibold">
              {formState.fields.email.value}
            </span>
            .
          </span>
        </div>,
      );
    },
    [formState.fields.email.value, navigate, setSession],
  );

  const handleEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const newValue = event.target.value;

      setFormState((previousState) => {
        const isDirty =
          previousState.fields.email.isTouched ||
          newValue !== previousState.fields.email.value;

        const result = emailSchema.safeParse(newValue);
        const isValid = result.success;
        if (!isValid) {
          Amplitude.track("Form Input Invalid", {
            formId: FORM_ID,
            inputId: "email",
          });
        }

        return {
          ...previousState,
          isDirty: previousState.isDirty || isDirty,
          isValid:
            isValid &&
            Object.entries(previousState.fields)
              .filter(([key, _]) => key !== "email")
              .every(([_, { isValid }]) => isValid),
          fields: {
            ...previousState.fields,
            email: {
              ...previousState.fields.email,
              value: newValue,
              isValid,
              isDirty,
            },
          },
        };
      });
    },
    [],
  );

  const handleBlur = useCallback(() => {
    setFormState((previousState) => ({
      ...previousState,
      isTouched: true,
      isValid: Object.values(previousState.fields).every(
        (field) => field.isValid,
      ),
      isDirty: Object.values(previousState.fields).every(
        (field) => field.isDirty,
      ),
      fields: {
        email: {
          ...previousState.fields.email,
          isTouched: true,
        },
      },
    }));
  }, []);

  const renderFieldAsValid = useCallback(
    (fieldKey: keyof FormState["fields"]): boolean => {
      const field = formState.fields[fieldKey];
      if (!field.isTouched || field.isValid) {
        return true;
      }

      if (field.isTouched && field.isDirty) {
        return field.isValid;
      }

      return true;
    },
    [formState.fields],
  );

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="request-password-reset-form"
        className="flex flex-col items-center justify-center gap-4"
        {...loginAnimation}
      >
        <section className="flex max-w-[324px] flex-col items-center gap-1 md:max-w-none">
          <Logo showText={false} size="md" className="mb-2" />
          <Title.Medium className="font-bold">Reset your password</Title.Medium>
          <Text className="text-center">
            {
              "Forgot your password? Enter your email, and we'll send a secure reset link."
            }
          </Text>
        </section>
        <form
          id={FORM_ID}
          name={FORM_ID}
          data-testid={FORM_ID}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-4">
            <section className="flex w-64 flex-col gap-2">
              <Input
                name="email"
                id="email"
                type="email"
                value={formState.fields.email.value}
                data-testid={`${FORM_ID}:email`}
                isValid={renderFieldAsValid("email")}
                onChange={handleEmailChange}
                onBlur={handleBlur}
                placeholder="Your Email Address"
                autoComplete="username"
                ref={emailInput}
                disabled={isLoading}
                autoFocus
              />
            </section>
            <Button
              data-testid={`${FORM_ID}:send`}
              disabled={isLoading || !formState.isValid}
              type="submit"
              isLoading={isLoading}
            >
              Send Password Reset Link
            </Button>
            <Button
              data-testid={`${FORM_ID}:back`}
              disabled={isLoading}
              className="bg-white dark:bg-neutral-700"
              primary={false}
              onClick={() => navigate(AccountAppRoutes.ROOT)}
            >
              Back to Login
            </Button>
          </div>
        </form>
      </motion.div>
    </AnimatePresence>
  );
};
