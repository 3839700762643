import {
  AddWalletMutation,
  AddWalletMutationVariables,
  ChangePasswordMutationVariables,
  NewSessionMutation,
  NewSessionMutationVariables,
  PerformEmailPasswordLoginMutation,
  PerformEmailPasswordLoginMutationVariables,
  PerformMagicLinkLoginMutation,
  PerformMagicLinkLoginMutationVariables,
  Send2FaCodeMutation,
  Send2FaCodeMutationVariables,
  SetThemeMutation,
  SetThemeMutationVariables,
  RemoveUserWalletMutationVariables,
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
  ResetPasswordMutationVariables,
  Verify2FaMutation,
  Verify2FaMutationVariables,
  getSdk,
  ChangePasswordMutation,
  TransfersQuery,
  AddPaymentCardMutation,
  AddPaymentCardMutationVariables,
} from "./generated/sdk";
import responseMiddleware from "./utils/middleware";
import { ClientError, GraphQLClient } from "graphql-request";
import { ErrorMessages } from "./utils/errorMessages";
import { Result, err, ok } from "neverthrow";
import { Sentry } from "./telemetry";
import { User } from "./types";
import { useNavigate } from "react-router-dom";

const MESO_AUTHORIZATION_HEADER = "Authorization";
const { VITE_GRAPHQL_ORIGIN } = import.meta.env;
const apiUrl =
  VITE_GRAPHQL_ORIGIN && typeof VITE_GRAPHQL_ORIGIN === "string"
    ? `${VITE_GRAPHQL_ORIGIN}/query`
    : `${location.origin}/query`;
var graphQLClient = new GraphQLClient(apiUrl, { errorPolicy: "all" });
export var sdk = getSdk(graphQLClient);

export const resetSessionIdentifier = () => {
  graphQLClient = new GraphQLClient(apiUrl);
  sdk = getSdk(graphQLClient);
};

export const setBearerToken = (
  token: string,
  navigate: ReturnType<typeof useNavigate>,
) => {
  graphQLClient = new GraphQLClient(apiUrl, {
    responseMiddleware: responseMiddleware(navigate),
    errorPolicy: "all",
    headers: { [MESO_AUTHORIZATION_HEADER]: `Bearer ${token}` },
  });
  sdk = getSdk(graphQLClient);
};

export const reportApiError = (error: unknown, operation: string) => {
  Sentry.captureException(
    error instanceof ClientError ? error.message : error,
    { tags: { operation } },
  );
};

export type ResolveUserResult = Result<User, string>;
export const resolveUser = async (): Promise<ResolveUserResult> => {
  const ERROR_MESSAGE = "Unable to retrieve User data.";
  try {
    const userResult = await sdk.User();
    const { user } = userResult;

    if (!user || user.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "User (self)" },
        extra: { userResult },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(user);
  } catch (error: unknown) {
    reportApiError(error, "User (self)");
  }
  return err(ERROR_MESSAGE);
};

export type ResolveVerify2FAResult = Result<
  Extract<Verify2FaMutation["verify2FA"], { __typename: "BooleanObject" }>,
  string
>;
export const resolveVerify2FA = async ({
  input,
}: Verify2FaMutationVariables): Promise<ResolveVerify2FAResult> => {
  const ERROR_MESSAGE = "Invalid code. Please try again.";

  try {
    const verify2FAResult = await sdk.Verify2FA({ input });
    const { verify2FA } = verify2FAResult;

    if (
      !verify2FA ||
      verify2FA.__typename === "Errors" ||
      verify2FA.bool === false
    ) {
      if (verify2FA?.__typename === "Errors") {
        Sentry.captureMessage(ERROR_MESSAGE, {
          level: "warning",
          tags: { operation: "Verify2FA" },
          extra: verify2FAResult,
        });
      }

      return err(ERROR_MESSAGE);
    }

    return ok(verify2FA);
  } catch (error: unknown) {
    reportApiError(error, "Verify2FA");
  }

  return err(ERROR_MESSAGE);
};

type ResolveAddWalletSuccessResult = {
  addWallet: Extract<
    AddWalletMutation["addWallet"],
    { __typename: "ProfileStatus" }
  >;
  user: Extract<AddWalletMutation["user"], { __typename: "User" }>;
};

export const resolveAddWallet = async ({
  input,
}: AddWalletMutationVariables): Promise<
  Result<ResolveAddWalletSuccessResult, string>
> => {
  const ERROR_MESSAGE = "Unable to add wallet.";
  const USER_ERROR_MESSAGE = "Unable to retrieve User.";
  try {
    const addWalletResult = await sdk.AddWallet({ input });
    const { addWallet, user } = addWalletResult;

    if (!addWallet || addWallet.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "AddWallet" },
        extra: { addWalletResult },
      });

      if (addWallet?.errors[0].code === "invalid_wallet_address") {
        return err(ErrorMessages.INVALID_WALLET_ADDRESS);
      }

      return err(ERROR_MESSAGE);
    }

    if (!user || user.__typename === "Errors") {
      Sentry.captureMessage(USER_ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "AddWallet" },
        extra: { addWalletResult },
      });

      return err(USER_ERROR_MESSAGE);
    }

    if (user.__typename === "User") {
      return ok({ addWallet, user });
    }
  } catch (error: unknown) {
    reportApiError(error, "AddWallet");
  }

  return err(ERROR_MESSAGE);
};

/**
 * Establish a new session with the Meso API.
 */
export type ResolveSessionResult = Result<
  Extract<NewSessionMutation["newSession"], { __typename: "Session" }>,
  string
>;
export const resolveSession = async ({
  input,
}: NewSessionMutationVariables): Promise<ResolveSessionResult> => {
  const ERROR_MESSAGE = "Unable to create session.";

  try {
    const newSessionResult = await sdk.NewSession({ input });
    const { newSession } = newSessionResult;

    if (!newSession || newSession.__typename === "Errors") {
      Sentry.captureMessage(newSession?.errors[0].enMessage || ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "NewSession" },
        extra: { newSession },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(newSession);
  } catch (error: unknown) {
    reportApiError(error, "NewSession");
  }

  return err(ERROR_MESSAGE);
};

export type ResolveSetThemeResult = Result<
  Extract<SetThemeMutation["setTheme"], { __typename: "BooleanObject" }>,
  string
>;
export const resolveSetTheme = async ({
  input,
}: SetThemeMutationVariables): Promise<ResolveSetThemeResult> => {
  const ERROR_MESSAGE = "Unable to set theme";

  try {
    const setThemeResult = await sdk.SetTheme({ input });
    const { setTheme } = setThemeResult;

    if (!setTheme || setTheme?.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "SetTheme" },
        extra: setThemeResult,
      });
      return err(ERROR_MESSAGE);
    }

    return ok(setTheme);
  } catch (error: unknown) {
    reportApiError(error, "SetTheme");
  }

  return err(ERROR_MESSAGE);
};

export const resolveSend2FACode = async ({
  input,
}: Send2FaCodeMutationVariables): Promise<
  Result<
    Extract<
      Send2FaCodeMutation["send2FACode"],
      { __typename: "BooleanObject" }
    >,
    string
  >
> => {
  const ERROR_MESSAGE = "Unable to resend 2FA code.";

  try {
    const send2FACodeResult = await sdk.Send2FACode({ input });
    const { send2FACode } = send2FACodeResult;

    if (
      !send2FACode ||
      send2FACode.__typename === "Errors" ||
      send2FACode.bool === false
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "Send2FACode" },
        extra: send2FACodeResult,
      });

      return err(ERROR_MESSAGE);
    }

    return ok(send2FACode);
  } catch (error: unknown) {
    reportApiError(error, "Send2FACode");
  }

  return err(ERROR_MESSAGE);
};

export const resolvePerformMagicLinkLogin = async ({
  input,
}: PerformMagicLinkLoginMutationVariables): Promise<
  Result<
    Extract<
      PerformMagicLinkLoginMutation["performMagicLinkLogin"],
      { __typename: "Login" }
    >,
    string
  >
> => {
  const ERROR_MESSAGE = "Unable to resolve magic link code.";

  try {
    const performMagicLinkLoginResult = await sdk.PerformMagicLinkLogin({
      input,
    });
    const { performMagicLinkLogin } = performMagicLinkLoginResult;

    if (
      !performMagicLinkLogin ||
      performMagicLinkLogin.__typename === "Errors"
    ) {
      return err(ERROR_MESSAGE);
    }

    return ok(performMagicLinkLogin);
  } catch (error: unknown) {
    reportApiError(error, "PerformMagicLinkLogin");
  }

  return err(ERROR_MESSAGE);
};

export type ResolvePerformEmailPasswordLoginResult = Result<
  Extract<
    PerformEmailPasswordLoginMutation["performEmailPasswordLogin"],
    { __typename: "Login" }
  >,
  string
>;
export const resolvePerformEmailPasswordLogin = async ({
  input,
}: PerformEmailPasswordLoginMutationVariables): Promise<ResolvePerformEmailPasswordLoginResult> => {
  const ERROR_MESSAGE = "Unable to perform login. Please contact support.";

  try {
    const performEmailPasswordLoginResult = await sdk.PerformEmailPasswordLogin(
      { input },
    );
    const { performEmailPasswordLogin } = performEmailPasswordLoginResult;

    if (
      !performEmailPasswordLogin ||
      performEmailPasswordLogin.__typename === "Errors"
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "PerformEmailPasswordLogin" },
        extra: performEmailPasswordLoginResult,
      });

      return err(ERROR_MESSAGE);
    }

    return ok(performEmailPasswordLogin);
  } catch (error: unknown) {
    reportApiError(error, "PerformEmailPasswordLogin");
  }

  return err(ERROR_MESSAGE);
};

export type ResolveChangePasswordResult = Result<
  Extract<
    ChangePasswordMutation["changePassword"],
    { __typename: "BooleanObject" }
  >,
  string
>;
export const resolveChangePassword = async ({
  input,
}: ChangePasswordMutationVariables): Promise<ResolveChangePasswordResult> => {
  const ERROR_MESSAGE = ErrorMessages.changePassword.UNABLE_TO_CHANGE_PASSWORD;

  try {
    const changePasswordResult = await sdk.ChangePassword({ input });
    const { changePassword } = changePasswordResult;

    if (!changePassword || changePassword.__typename === "Errors") {
      if (changePassword?.errors[0].code === "password_reused") {
        return err(ErrorMessages.changePassword.REUSED);
      } else if (changePassword?.errors[0].code === "bad_current_password") {
        return err(ErrorMessages.changePassword.BAD_CURRENT_PASSWORD);
      } else {
        Sentry.captureMessage(ERROR_MESSAGE, {
          level: "warning",
          tags: { operation: "ChangePassword" },
          extra: changePasswordResult,
        });
      }

      return err(ERROR_MESSAGE);
    }

    if (!changePassword.bool) {
      return err(ERROR_MESSAGE);
    }

    return ok(changePassword);
  } catch (error: unknown) {
    reportApiError(error, "ChangePassword");
  }

  return err(ERROR_MESSAGE);
};

export type ResolveRequestPasswordResetResult = Result<
  Extract<
    RequestPasswordResetMutation["requestPasswordReset"],
    { __typename: "BooleanObject" }
  >,
  string
>;

export const resolveRequestPasswordReset = async ({
  input,
}: RequestPasswordResetMutationVariables): Promise<ResolveRequestPasswordResetResult> => {
  const ERROR_MESSAGE =
    "Unable to request password reset. Please contact support.";

  try {
    const requestPasswordResetResult = await sdk.RequestPasswordReset({
      input,
    });
    const { requestPasswordReset } = requestPasswordResetResult;

    if (!requestPasswordReset || requestPasswordReset.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: "RequestPasswordReset" },
        extra: requestPasswordResetResult,
      });

      return err(ERROR_MESSAGE);
    }

    return ok(requestPasswordReset);
  } catch (error: unknown) {
    reportApiError(error, "RequestPasswordReset");
  }

  return err(ERROR_MESSAGE);
};

export const resolveResetPassword = async ({
  input,
}: ResetPasswordMutationVariables): Promise<Result<true, string>> => {
  const ERROR_MESSAGE = ErrorMessages.resetPassword.UNABLE_TO_RESET_PASSWORD;

  try {
    const resetPasswordResult = await sdk.ResetPassword({ input });
    const { resetPassword } = resetPasswordResult;

    if (!resetPassword || resetPassword.__typename === "Errors") {
      if (resetPassword?.errors[0].code === "password_reused") {
        return err(ErrorMessages.resetPassword.REUSED);
      } else if (
        resetPassword?.errors[0].code === "unauthorized_password_reset"
      ) {
        return err(ErrorMessages.resetPassword.UNAUTHORIZED);
      } else {
        Sentry.captureMessage(ERROR_MESSAGE, {
          level: "warning",
          tags: { operation: "ResetPassword" },
          extra: resetPasswordResult,
        });
      }

      return err(ERROR_MESSAGE);
    }

    if (!resetPassword.bool) {
      return err(ERROR_MESSAGE);
    }

    return ok(resetPassword.bool);
  } catch (error: unknown) {
    reportApiError(error, "ResetPassword");
  }

  return err(ERROR_MESSAGE);
};

type ResolveRemoveUserWalletResult = Result<true, string>;
export const resolveRemoveUserWallet = async ({
  input,
}: RemoveUserWalletMutationVariables): Promise<ResolveRemoveUserWalletResult> => {
  const OPERATION_NAME = "RemoveUserWallet";
  const ERROR_MESSAGE = ErrorMessages.removeWallet.UNABLE_TO_REMOVE_WALLET;

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const { removeUserWallet } = result;

    if (
      !removeUserWallet ||
      removeUserWallet.__typename === "Errors" ||
      !removeUserWallet.bool
    ) {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: { result: JSON.stringify(result) },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(removeUserWallet.bool);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveTransfersResult = Result<
  Extract<
    TransfersQuery["transfers"],
    { __typename?: "Transfers" }
  >["collection"],
  string
>;
export const resolveTransfers = async (): Promise<ResolveTransfersResult> => {
  const OPERATION_NAME = "Transfers";
  const ERROR_MESSAGE = "Unable to fetch transfers.";

  try {
    const result = await sdk[OPERATION_NAME]();
    const { transfers } = result;

    if (!transfers || transfers.__typename !== "Transfers") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: { result: JSON.stringify(result) },
      });

      return err(ERROR_MESSAGE);
    }
    return ok(transfers.collection);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};

export type ResolveAddPaymentCardResult = Result<
  Extract<
    AddPaymentCardMutation["addPaymentCard"],
    { __typename: "ProfileStatus" }
  >,
  string
>;
export const resolveAddPaymentCard = async ({
  input,
}: AddPaymentCardMutationVariables): Promise<ResolveAddPaymentCardResult> => {
  const OPERATION_NAME = "AddPaymentCard";
  const ERROR_MESSAGE = "Unable to add payment card.";

  try {
    const result = await sdk[OPERATION_NAME]({ input });
    const { addPaymentCard } = result;

    if (!addPaymentCard || addPaymentCard.__typename === "Errors") {
      Sentry.captureMessage(ERROR_MESSAGE, {
        level: "warning",
        tags: { operation: OPERATION_NAME },
        extra: { result: JSON.stringify(result) },
      });

      return err(ERROR_MESSAGE);
    }

    return ok(addPaymentCard);
  } catch (error: unknown) {
    reportApiError(error, OPERATION_NAME);
  }

  return err(ERROR_MESSAGE);
};
